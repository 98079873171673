/**
Utility classes

Commonly used layouting / styling.
*/
@use '@angular/material' as mat;
@use 'plantdb/theme';
@import 'plantdb/mixins';


/**
 * Flex row - bring block elements next to each other.
 */
.app-flex {
    display: flex;
    gap: 1em;

    // Mobile:
    // TODO: or use media query to change flex direction
    flex-wrap: wrap;
}

.app-flex-max {
    justify-content: space-between;
}
.app-items-center {
    align-items: center;
  }


/**
 * Add this to elements to make them hidden on small screens
 */
.hide-mobile {
    display: none;
    @media only screen and (min-width: theme.$breakpoint-phone) {
        display: initial;
    }
}

.app-right {
    text-align: right;
}