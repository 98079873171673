/**
Custom Components
*/
@use '@angular/material' as mat;
@use 'plantdb/theme';
@import 'plantdb/mixins';

/**
 * Chip elements
 */
.app-simple-chip {
    border-radius: 16px;
    display: inline-flex;
    gap: .25em;
    padding: 5px 15px;
    background: mat.m2-get-color-from-palette(theme.$accent, 200);
    &:first-child {
        margin-left: 0;
    }
    height: 32px;
    border: 1px solid mat.m2-get-color-from-palette(theme.$accent, 500);
}
.app-simple-chip:hover {
    transform: scale(105%);
}


.app-panel {
  @include accent-block-base;
  border-radius: 6px;
  max-width: var(--limit-width-plus);
}

/**
 * SUB MENU
 */
.app-sub-menu {
    display: flex;
    flex-wrap: wrap;
    gap: .5em;
    > * { width: 200px; }

    @media (max-width: theme.$breakpoint-tablet) {
        /* on smaller screens, give the panels full width so it stacks */
        >* {
            width: unset;
        }
    }
    margin: .5em 0;
}

.app-sub-menu div.app-panel {
    padding: .5em;
    background: #eee;

    h4 {
        margin: 0;
    }

    a {
        display: block;
        border-bottom: initial;
    }

    // border-radius: 25px;
    // padding: .5em 1em;
    // box-shadow: 1px 1px 5px #999;
}


/************************************************
 * app-bar content
 *
 * sidebar-like content with headings and heavy borders
 *
 ************************************************/

 .app-bar-content {

    &.with-topbar {
      border-top: 8px solid;
      border-top-color: mat.m2-get-color-from-palette(theme.$accent, 900);
    }

    padding: .25em 0;

    &.size-small {
      max-width: 20em;
    }

    &.size-limit {
        max-width: var(--limit-width);
    }

    &.spacing {
        margin: 1em 0;
    }

    h3 {
      margin: .25em 0;
    }

    p {
      font-size: 95%;
    }

    .boxes {
      display: flex;
      flex-direction: column;
      gap: .5em;
    }
    .boxes > div {
      padding: .75em;
      background: mat.m2-get-color-from-palette(theme.$accent, 50);
      display: grid;
      grid-template-columns: 2em auto;
      gap: 0 1em;

      mat-icon {
        color: mat.m2-get-color-from-palette(theme.$primary, 900);
        margin: 4px;
      }

      &.noheader {
        mat-icon {
          margin-top: 0px;
        }
      }

      .mat-h2 {
        color: mat.m2-get-color-from-palette(theme.$primary, 900);
        margin: 0;
        font-weight: bold;
      }
      p {
        grid-column-start: 2;
        margin: 0.25em 0;
      }

      &.alert {
        background: mat.m2-get-color-from-palette(theme.$warn, 50);
        mat-icon.alert {
          color: mat.m2-get-color-from-palette(theme.$warn, 900);
        }
      }
    }

    &.alert {
      border-top-color: mat.m2-get-color-from-palette(theme.$warn, 200);
    }
  }